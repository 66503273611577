.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

.function-list {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.response-section {
  flex: 2;
  padding: 20px;
  max-height: 300px; /* Adjust this value based on your design preference */
  overflow-y: auto;  /* Makes content vertically scrollable when it overflows */
}

button {
  display: block;
  margin-bottom: 10px;
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}
